import React from "react"
import { graphql } from "gatsby"
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Heading from "../../components/content/heading"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import Rows from "../../components/grid/rows"
import Columns from "../../components/grid/columns"
import { Content, Footer } from "../../components/grid"
import Main from "../../components/layout/main"
import Seo from "../../components/seo/seo"
import Copy from "../../components/content/copy"
import SectionBackground from "../../components/containers/section-background"
import TryUsBar from "../../components/sections/try-us-bar"
import Card from "../../components/containers/card"
import EmployeeTestimonialCarousel from "../../components-smart/carousel/employee-testimonial-carousel"
import CtaGroup from "../../components/containers/cta-group"
import Link from "../../components/content/link"
import CareerListing from "@src/sections/careers/career-listing"
import { Link as ScrollLink } from "react-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faShoePrints,
  faHeart,
  faDoorOpen,
  faGear,
} from "@fortawesome/free-solid-svg-icons"

function CareerPage({ data, location }) {
  const {
    header,
    seo,
    columns,
    employeeTestimonial,
    careerOpportunities,
    glassdoorSection,
  } = data.strapiPageCareers

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image--careers" />
          </SectionBackground>
          <SectionContent paddingBot="none">
            <Rows gap="xxl" className="mt-xxl mb-lg">
              <Columns count="1" alignment="center">
                <Content className="tab-mx-xxl" gap="xl">
                  <Heading alignment="center" level={1}>
                    Join the{" "}
                    <span className="text-gradient text-gradient__purple-blue">
                      Lunio Team
                    </span>
                  </Heading>
                  <Copy className="mx-xl">
                    <p className="text--center">{header.postHeadingText}</p>
                  </Copy>
                  <Footer>
                    <CtaGroup alignment="center">
                      <p>
                        <ScrollLink
                          className="link"
                          smooth
                          offset={-20}
                          to="roles"
                        >
                          View Roles
                        </ScrollLink>
                      </p>
                    </CtaGroup>
                  </Footer>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent>
          <Rows gap="xxl">
            <Columns count="4">
              {columns.map(({ image, text, title }, index) => {
                const imgData = getImage(image.localFile)
                return (
                  <Content key={index}>
                    <div>
                      <GatsbyImage
                        image={imgData}
                        className="mr-lg"
                        alt={image.alternativeText}
                      />
                    </div>
                    <Heading level={4}>{title}</Heading>
                    <Copy contentToParse={text} />
                  </Content>
                )
              })}
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="xs" paddingBot="xs">
          <div className="border--rounded text-gradient__midgard relative">
            <EmployeeTestimonialCarousel
              cardsData={employeeTestimonial.testimonial}
            />
          </div>
        </SectionContent>
      </Section>

      <CareerListing careerOpportunities={careerOpportunities} />

      <Section>
        <SectionContent paddingTop="xs">
          <Rows>
            <Columns count="2" alignment="center">
              <Content paddingLeft="none" paddingRight="none">
                <StaticImage
                  src="../../images/careers/careers-collab.png"
                  alt="Collaboration at Lunio"
                />
              </Content>
              <Content paddingLeft="md">
                <Heading>
                  Be Part of a High-Performing ,{" "}
                  <span className="text-gradient text-gradient__ragnarok">
                    Collaborative
                  </span>{" "}
                  Team
                </Heading>
                <Copy>
                  <p>
                    Collaboration is at the heart of everything we do. We embody
                    it in our company culture as we align, design, and iterate
                    to solve our clients’ toughest challenges.
                  </p>
                </Copy>
              </Content>
            </Columns>
            <Columns count="2" alignment="center">
              <Content paddingLeft="md">
                <Heading>
                  Accelerate Your Career in a{" "}
                  <span className="text-gradient text-gradient__arcade">
                    Hyper-Growth
                  </span>{" "}
                  Company
                </Heading>
                <Copy>
                  <p>
                    We’ve grown 150% year-on-year. We’re open to new challenges
                    and not afraid to fail. And we all strive to create better
                    versions of ourselves by learning from the best experts and
                    advisors.
                  </p>
                </Copy>
              </Content>
              <Content paddingLeft="none" paddingRight="none">
                <StaticImage
                  src="../../images/careers/careers-growth.png"
                  alt="Growth at lunio"
                />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="xs" paddingBot="xxs">
          <div className="border--rounded relative text-gradient__pastel overflow--hidden">
            <Rows className="pt-xl px-lg">
              <Columns count="1">
                <Content className="pb-sm lap-px-xxl">
                  <Heading alignment="center">
                    Lunio’s Commitment to Diversity
                  </Heading>
                  <Copy>
                    <p className="text--center">
                      We believe the best companies bring together diversity in
                      race, age, physical ability, sexuality, gender identity,
                      ethnicity, perspectives and ideas.
                    </p>
                    <p className="text--center">
                      And we know people do their best work when they feel like
                      they belong. Somewhere where they’re included, valued, and
                      equal. So every day, we strive to make Lunio a more
                      diverse company, and we promise to keep doing better.
                    </p>
                  </Copy>
                </Content>
              </Columns>
              <Columns>
                <div className="flex flex--justify-center">
                  <StaticImage src="../../images/careers/diversity.png" />
                </div>
              </Columns>
            </Rows>
          </div>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="none" paddingBot="xs">
          <Rows>
            <Columns count="2" colgap>
              <Card styleType="solid">
                <Content gap="sm">
                  <p className="h2">
                    <FontAwesomeIcon
                      className="text--color-ragnarok"
                      icon={faHeart}
                    />
                  </p>
                  <Heading level={3}>
                    We Practice Empathy to Learn & Improve
                  </Heading>
                  <Copy>
                    <p>
                      We’re all about making the internet a more human place. So
                      we look at things from the perspective of our clients and
                      each other to deepen our understanding and grow together.
                    </p>
                  </Copy>
                </Content>
              </Card>
              <Card styleType="solid">
                <Content gap="sm">
                  <p className="h2">
                    <FontAwesomeIcon
                      className="text--color-midgard"
                      icon={faGear}
                    />
                  </p>
                  <Heading level={3}>
                    We Focus on Impact & Make Things Happen
                  </Heading>
                  <Copy>
                    <p>
                      Freedom and responsibility go hand in hand. There's no
                      micromanaging here, but we all strive to make a measurable
                      impact every day. We trust each other. And hold ourselves
                      accountable.
                    </p>
                  </Copy>
                </Content>
              </Card>
              <Card styleType="solid">
                <Content gap="sm">
                  <p className="h2">
                    <FontAwesomeIcon
                      className="text--color-terra"
                      icon={faDoorOpen}
                    />
                  </p>
                  <Heading level={3}>We Leave Egos at the Door</Heading>
                  <Copy>
                    <p>
                      We take pride in our work but have the humility to admit
                      when things haven’t gone to plan. Because we know that’s
                      how we all get better. Work can be funny sometimes. And
                      it’s good to laugh.
                    </p>
                  </Copy>
                </Content>
              </Card>
              <Card styleType="solid">
                <Content gap="sm">
                  <p className="h2">
                    <FontAwesomeIcon
                      className="text--color-nebula"
                      icon={faShoePrints}
                    />
                  </p>
                  <Heading level={3}>We Stay One Step Ahead</Heading>
                  <Copy>
                    <p>
                      We know that to get where we’re going we have to be
                      conscientious, think big, and solve problems before they
                      even arise. When it comes to our work, we always think and
                      act one step ahead.
                    </p>
                  </Copy>
                </Content>
              </Card>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <TryUsBar />
    </Main>
  )
}

export const query = graphql`
  query CareerPageQuery {
    strapiPageCareers {
      seo {
        metaTitle
        metaDesc
        shareImage {
          alternativeText
          localFile {
            publicURL
          }
        }
      }
      header {
        headingText
        postHeadingText
      }
      columns: columnImageTextBlock {
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        text
        title
      }
      employeeTestimonial {
        title {
          preHeading
          primaryHeading
        }
        testimonial {
          text
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      managedSolution {
        text
        heading {
          preHeading
          primaryHeading
        }
      }
      careerOpportunities {
        primaryHeading
        preHeading
      }
      glassdoorSection {
        glassdoorLink
      }
    }
  }
`

export default CareerPage
