import React from "react"
import Section from "@src/components/containers/section"
import SectionContent from "@src/components/containers/section-content"
import Heading from "@src/components/content/heading"
import Columns from "@src/components/grid/columns"
import Content from "@src/components/grid/content"
import Rows from "@src/components/grid/rows"
import { StaticImage } from "gatsby-plugin-image"
import Button from "@src/components/interactive/button"

const CareerListing = ({ careerOpportunities }) => {
  return (
    <Section id="roles">
      <SectionContent>
        <Rows gap="lg">
          <Columns count="2,1" alignment="center">
            <Content paddingRight="none" paddingLeft="none">
              <Heading
                alignment="left"
                preheading={careerOpportunities.preHeading}
              >
                {careerOpportunities.primaryHeading}
              </Heading>
            </Content>
            <Content>
              <div className="hidden--tab-down" style={{ textAlign: "right" }}>
                <Button href="https://app.screenloop.com/careers/lunio" openTab>
                  View Roles
                </Button>
              </div>
              <div className="hidden--tab-up">
                <Button href="https://app.screenloop.com/careers/lunio" openTab>
                  View Roles
                </Button>
              </div>
            </Content>
          </Columns>
        </Rows>
      </SectionContent>
    </Section>
  )
}

export default CareerListing
