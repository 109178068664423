import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Columns, Content, Rows } from "../../components/grid"
import Copy from "../../components/content/copy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"

function EmployeeTestimonialCard({ image, title, text }) {
  const img = getImage(image.localFile)

  return (
    <Rows className="pl-md pr-md tab-pr-none pt-md lap-pt-lg tab-px-md">
      <Columns className="careers-slider__columns" alignment="center">
        <Content className="pb-sm lap-pb-lg relative">
          <FontAwesomeIcon
            icon={faQuoteLeft}
            className="absolute text--color-white opc-25 h1"
            style={{ left: -8, top: -24 }}
          />
          <Copy className="careers-slider__text" contentToParse={text} />
          <p className="text--strong text--color-white">{title}</p>
        </Content>
        <Content
          className="flex--justify-end hidden--tab-down"
          paddingRight="sm"
        >
          <GatsbyImage image={img} alt={title} />
        </Content>
      </Columns>
    </Rows>
  )
}

export default EmployeeTestimonialCard
