import React from "react"
import Carousel from "./default"
import EmployeeTestimonialCard from "./employee-testimonial-card"

function EmployeeTestimonialCarousel({ cardsData }) {
  const responsiveSlides = {
    full: 1,
    mid: 1,
    small: 1,
  }

  const cards = cardsData.map(({ image, text, title }, index) => (
    <EmployeeTestimonialCard
      key={index}
      image={image}
      title={title}
      text={text}
    />
  ))

  return (
    <Carousel
      autoplay={true}
      slidesToShow={responsiveSlides}
      infinite
      className="careers-slider"
      slides={cards}
    />
  )
}

export default EmployeeTestimonialCarousel
